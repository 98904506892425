$(document).ready(() => {
  let preview = false;
  let $start = $('#start');
  let $text = $('#text');
  let $kurtyna = $('#kurtyna');
  let $wishes = $('#wishes');
  let $thanks = $('#thanks');
  let $overlay = $('#overlay');
  let $send = $('#send');
  let $again = $('.again');
  let $bestWishes = $('#best-wishes');
  let $bestWishesText = $('#best-wishes-text');
  let $kuknij = $('#kuknij');
  let $kuknijOverlay = $('#kuknij-overlay');
  let $header = $('#header');

  let $videoKurtyna = $('#video-kurtyna');
  let $videoTlo = $('#video-tlo');
  let $videoBohater = $('#video-bohater');

  let $zyczenia = $('#zyczenia');
  let $imie = $('#name');
  let $email = $('#email');
  let $podpis = $('#podpis');

  let $kurtynaLeft = $('#kurtyna-left');
  let $kurtynaRight = $('#kurtyna-right');

  let $audio = $('#audio');

  let videoRunning = 0;

  $videoKurtyna.get(0).currentTime = 2;
  $text.hide();
  $kurtyna.hide();
  $wishes.hide();
  $thanks.hide();
  $bestWishes.hide();

  initVideo();

  function initVideo() {
    let video = $videoKurtyna.get(0);
    video.play(); //start loading, didn't used `vid.load()` since it causes problems with the `ended` event

    if(video.readyState !== 4){ //HAVE_ENOUGH_DATA
      video.addEventListener('canplaythrough', start, false);
      video.addEventListener('load', start, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
      setTimeout(function(){
        video.pause(); //block play so it buffers before playing
      }, 10); //it needs to be after a delay otherwise it doesn't work properly.
    }
  }

  function start(){
    let video = $videoKurtyna.get(0);

    video.removeEventListener('canplaythrough', start, false);
    video.removeEventListener('load', start, false);

    $kuknijOverlay.show();

    $audio.get(0).volume = 0.6;
    $audio.get(0).play();

    TweenMax.to($('body'), 0.3, {opacity: 1});
    TweenMax.from($kuknijOverlay, 0.3, {opacity: 0});

  }

  let form = {
    name: getParameterByName('n'),
    gender: getParameterByName('g'),
    body: getParameterByName('b'),
    signature: getParameterByName('s')
  };

  $('#share').on('click', function(e) {
    e.preventDefault();

    FB.ui({
      method: 'share',
      href: getLink(),
    }, function(response){});
  });

  preview = !!form.name;

  // runDemo();

  function runDemo() {
    form.name = 'Łukasz';
    form.gender = 'male';

    $videoKurtyna.hide();

    runAnimate(form.name, form.gender);

    $videoBohater.get(0).currentTime = 64;
    setTimeout(() => {
      // $videoBohater.get(0).pause();
    }, 7000);
  }

  $videoKurtyna.on('ended', (e) => {
    if (preview) {
      runAnimate(form.name, form.gender);
    } else {
      let tween = new TimelineMax({
        onComplete: () => {
          $videoKurtyna.remove();
        }
      });


      tween.to($videoTlo, 0.5, {opacity: 1});
      tween.to($overlay, 0.3, {opacity: 1}, '-=0.2');

      $kurtyna.show();
    }
  });

  $videoBohater.on('timeupdate', videoHandler);

  let supermanAnimating = 0;
  function videoHandler(e) {
    let time = e.target.currentTime;

    if (time >= 47 && supermanAnimating === 0) {
      supermanAnimating = 1;

      $text.html(form.name);
      $text.show();
      TweenMax.to($text, 0.3, {opacity: 1});
    } else if (time >= 52.5 && supermanAnimating === 1) {
      supermanAnimating = 2;
      TweenMax.to($text, 0.2, {
        opacity: 0, onComplete: () => {
          $text.hide();
        }
      });
    } else if(((form.gender === 'male' && time >= 64.8) || (form.gender === 'female' && time >= 64.8)) && supermanAnimating === 2) {
      supermanAnimating = 3;
      $text.show();

      $text.addClass('phase-2');
      TweenMax.to($text, 0.3, {opacity: 1});
    } else if (time >= 85 && supermanAnimating === 3) { // 3 sec
      supermanAnimating = 4;
      $kurtyna.hide();

      if (preview) {
        let body = form.body;
        body += '\nWinszuje, ' + form.signature;

        $bestWishesText.html(body.replace(/\n/g, '<br>'));
        $bestWishes.show();
      } else {
        $wishes.show();
      }

      let $video = $videoBohater;

      let tween = new TimelineMax({
        onComplete: () => {
          $video.remove();

          $header.removeAttr('style');
          videoRunning = 3;
          calculateWidths();
          $audio.get(0).play();
        }
      });
      tween.to($video, 0.3, {opacity: 0}, 'video');
      tween.to($videoTlo, 0.3, {opacity: 1}, 'video');
      tween.to($text, 0.3, {opacity: 0}, '-=0.3');
      tween.to($overlay, 0.3, {opacity: 1}, '-=0.3');
    }
  }

  function runAnimate(name, gender) {
    $audio.get(0).pause();
    $audio.get(0).currentTime = 0;

    let tween = new TimelineMax({
      onComplete: () => {
        $kurtyna.hide();
      }
    });

    videoRunning = 2;

    let $video = $videoBohater;
    let source = '';

    if(gender === 'female') {
      $video = $videoBohater;
      source =
        '<source src="video/wonderwoman.webm" type="video/webm">' +
        '<source src="video/wonderwoman.mp4" type="video/mp4">';

    } else {
      source =
        '<source src="video/superman.webm" type="video/webm">' +
        '<source src="video/superman.mp4" type="video/mp4">';
    }

    $video.html(source);
    $video.get(0).load();

    tween.to($overlay, 0.3, {opacity: 0}, 'overlay');
    tween.to($video, 0.3, {opacity: 1});

    $text.html(name);

    // $video.get(0).currentTime = 40;
    // setTimeout(() => {
    //   $video.get(0).pause();
    // }, 7000);

    $('body').addClass(gender);

    $header.height(0);
    setTimeout(() => {
      $video.get(0).play();
      calculateWidths();
    }, 10);
  }

  $kuknij.on('click', (e) => {
    e.preventDefault();

    $videoKurtyna.get(0).play();
    videoRunning = 1;

    // calculateWidths();
    TweenMax.to($kuknijOverlay, 0.3, {opacity: 0, onComplete: () => {
        $kuknijOverlay.hide();
    }});
  });

  $start.on('click', (e) => {
    e.preventDefault();

    let name = $imie.val();
    let gender = $('.gender.selected');

    if (!name || !gender.length || name === $imie.data('default')) {
      alert('Sprawdź formularz');
      return;
    }

    form.name = name;
    form.gender = gender.data('gender');

    runAnimate(form.name, form.gender);
  });

  $send.on('click', (e) => {
    e.preventDefault();

    let email = $email.val();
    let body = $zyczenia.val();
    let name = $imie.val();
    let signature = $podpis.val();

    if (!email || !body || !signature
      || email === $email.data('default')
      || signature === $podpis.data('default')) {
      alert('Sprawdź formularz');
      return;
    }

    let link = getLink();

    $.post('send.php', {
      email: email,
      name: name,
      body: body,
      signature: signature,
      link: link,
    }, function (res) {
      if (res === 'ok') {
        $wishes.fadeOut(300, () => {
          $thanks.fadeIn(300);
        })
      } else {
        alert('Wystąpił błąd :( Spróbuj ponownie później!');

      }
    }).fail(() => {
      alert('Wystąpił błąd :( Spróbuj ponownie później!');
    });
  });

  function getLink() {
    let date = new Date();
    let timestamp = date.getTime();
    let body = $zyczenia.val();
    let signature = $podpis.val();

    return 'https://swiatecznagymba.pl/' +
      '?g=' + encodeURIComponent(form.gender) +
      '&n=' + encodeURIComponent(form.name) +
      '&b=' + encodeURIComponent(body) +
      '&s=' + encodeURIComponent(signature) +
      '&t=' + timestamp;
  }

  $again.on('click', (e) => {
    e.preventDefault();
    window.location = window.location.href.split("?")[0];
  });

  // gender
  $('.gender').on('click', function () {
    let $selected = $('.gender.selected');
    let $this = $(this);

    if ($this.data('gender') !== $selected.data('gender')) {
      $selected.removeClass('selected');
      $this.addClass('selected');
    }
  });

  function preloadVideo(video) {
    let req = new XMLHttpRequest();
    req.open('GET', 'video/' + video + '.mp4', true);
    req.responseType = 'blob';

    req.onload = function() {
      // Onload is triggered even on 404
      // so we need to check the status code
      if (this.status === 200) {
        let videoBlob = this.response;
        let vid = URL.createObjectURL(videoBlob); // IE10+
        // Video is now downloaded
        // and we can set it as source on the video element
        video.src = vid;
      }
    };
    req.onerror = function() {
      // Error
    };

    req.send();
  }

  $('input').on('focus', focus);
  $('input').on('blur', blur);

  function blur(e) {
    let val = $.trim($(this).val());
    if(!val || !val.length) {
      $(this).val($(this).data('default'));
    }
  }

  function focus(e) {
    if(!$(this).data('default')) {
      $(this).data('default', $(this).val());
    }

    let defaultValue = $(this).data('default');
    if($(this).val() === defaultValue) {
      $(this).val('');
    }
  }

  // UTILS
  let cookieKey = 'privacy-policy';
  let $cookies = $('#cookies');

  if (Cookies.get(cookieKey)) {
    $cookies.remove();
  } else {
    $cookies.on('click', '.close', () => {
      $cookies.remove();
      Cookies.set(cookieKey, true);
    });

    $cookies.show();
  }

  let $ratio = $('#ratio');
  let $headerContainer = $('#header-container');
  let $html = $('html');

  $(window).on('resize', calculateWidths);

  calculateWidths();

  function calculateWidths() {
    let header = $header.height();
    let height = $(window).height();
    console.log('header', header);

    height = height - header;

    let width = height / (9 / 16);

    $headerContainer.width(width);
    $ratio.width(width);

    width = $ratio.width();
    let windowWidth = $(window).width();

    let kurtyna = (windowWidth - width) / 2;

    if(videoRunning !== 2) {
      $kurtynaLeft.width(kurtyna);
      $kurtynaRight.width(kurtyna);
    } else {
      $kurtynaLeft.width(kurtyna + (width * 0.125));
      $kurtynaRight.width(kurtyna + (width * 0.128));
    }

    let fontSize = (width * 16) / 1280;
    $html.css('font-size', fontSize + 'px');
  }
});


function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
